import $ from 'jquery'
import lightbox from "lightbox2";
import imagesLoaded from 'imagesloaded';
import Siema from 'siema';
import 'lightbox2/dist/css/lightbox.min.css';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import bulmaCollapsible from '@creativebulma/bulma-collapsible'

window.jQuery = window.$ = $
window.lightbox = lightbox;
window.imagesLoaded = imagesLoaded;
window.Siema = Siema;
window.tippy = tippy;
$(document).ready(function () {

    //ReLoadImages();

    // Tooltips
    // With the above scripts loaded, you can call `tippy()` with a CSS
    // selector and a `content` prop:
    tippy('[data-tooltip]', {
        content: (reference) => reference.getAttribute('title'),
    });

    // Accordion
    const bulmaCollapsibleContainer = false; //document.getElementById('accordion-container');

    if (bulmaCollapsibleContainer) {
        const bulmaCollapsibleInstances = bulmaCollapsible.attach('#accordion-container .is-collapsible');

        bulmaCollapsibleInstances[0].collapse();
    }

    var height = $(window).height(),
        width = $(window).width();
    $(window).on('resize', function () {
        height = $(window).height();
        width = $(window).width();
    });

    // When on subpage scroll to page title...
    /**
     let $pageTitle = $('#page-title');
     let $video = $('.hero.is-fullheight');
     let currentScrollPos = document.documentElement.scrollTop || document.body.scrollTop;

     // Only scroll down on page, never up
     if ($pageTitle.length && $video.length && currentScrollPos < $pageTitle.offset().top) {
     $('html, body').animate({
     scrollTop: $pageTitle.offset().top - 160
     }, 1500);

     }
     **/

    function slowScrollToElement(el) {

        let target = $(el).attr('href');
        console.log(target)

        $('html, body').animate({
            scrollTop: $(target).offset().top - 100
        }, 1000);
    }

    function slowScrollToAnchor(anchor) {

        let target = $(anchor);
        console.log(target)

        $('html, body').animate({
            scrollTop: $(target).offset().top - 100
        }, 1000);
    }

    $('.slow-scroll').on('click', function (e) {
        e.preventDefault();
        console.log(e, e.target)
        slowScrollToElement($(e.target))
    })

    /**
     * Belongs to the old navbar
     function checkNavbarStatus() {
     var position = $(window).scrollTop();
     var breakpoint = height;

     if (position > breakpoint) {
     //$('nav.navbar').addClass('light').removeClass('dark');
     } else {
     //$('nav.navbar').addClass('dark').removeClass('light');
     }
     }

     // Switch dark/light mode on navbar
     checkNavbarStatus();
     $(window).on('scroll', checkNavbarStatus);
     **/

    // Handle closing of mobile menu
    $('.navbar-burger, #mobile-menu .close').click(function (e) {

        if ($(this).hasClass('normal-menu')) {
            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            $(".navbar-burger").toggleClass("is-active");
            $(".navbar-menu").toggleClass("is-active");
            return false;
        }

        var $button = e.target;
        var $target = $('body');
        $target.toggleClass('menuopen');

    });

    // Single object tabs
    $('.tabs .tab-link').on('click', function (e) {

        let $tabsContainer = $('#object-tabs');

        if (!$(this).hasClass('follow-link')) {
            e.preventDefault();
            let $thisLink = $(this);
            let newActive = $thisLink.attr('href');
            console.log($thisLink.attr('href'), newActive)
            console.log('Switch to ' + newActive);
            // If already active, do nothing
            if ($thisLink.parent().hasClass('is-active')) return false;
            console.log('Switch tab')
            // Sett active tab item
            $tabsContainer.find('.item.is-active').removeClass('is-active')
            let $newActiveTab = $tabsContainer.find('a[href="' + newActive + '"]');
            $newActiveTab.parent().addClass('is-active');
            //$thisLink.closest('.tabs').find('.item.is-active').removeClass('is-active');
            //$thisLink.parent().addClass('is-active');

            let $contentContainer = $('.tabs-content');

            $contentContainer.find('.is-active').removeClass('is-active');
            $contentContainer.find('.tab-content' + newActive).addClass('is-active');

            if ($thisLink.hasClass('slow-scroll')) {
                slowScrollToAnchor('#object-tabs')
            }
        }

    });

    $('#top-planning-link').on('click', function (e) {

        let isMobile = $('body').hasClass('is-mobile');

        if (!isMobile) { // Tabs for desktop
            let $thisLink = $('.tabs .item [href="#planritning"]');
            let $newActive = '#planritning';

            // Sett active tab item
            $thisLink.closest('.tabs').find('.item.is-active').removeClass('is-active');
            $thisLink.parent().addClass('is-active');

            let $contentContainer = $('.tabs-content');

            $contentContainer.find('.is-active').removeClass('is-active');
            $contentContainer.find($newActive).addClass('is-active');
        } else {

        }

    });

    // Default Lightbox settings
    lightbox.option({
        'albumLabel': 'Bild %1 av %2',
        "disableScrolling": true,
        "resizeDuration": 500,
        "wrapAround": true,
        'alwaysShowNavOnTouchDevices': true
    })

    // Bottom slider
    if ($('#footer-slider').length > 0) {

        const siemaSelector = document.querySelector('#footer-slider');
        const siemaSelectorItems = siemaSelector.children.length;

        const mySiema = new Siema({
            selector: siemaSelector,
            perPage: {
                768: siemaSelectorItems >= 4 ? 4 : siemaSelectorItems,
            },
            loop: true,
            duration: 300,

        })

        document.querySelector('.slider-container .prev').addEventListener('click', () => mySiema.prev(2));
        document.querySelector('.slider-container .next').addEventListener('click', () => mySiema.next(2));

    }


    // Set custom share urls for social medias
    function _share_url(id) {
        return window.location.protocol + "//" + window.location.host + "/objekt/" + id;
    }

    $(".facebook-share").click(function () {
        let id = $(this).attr("oid");
        let url = $(this).attr("share_url");
        //let url = _share_url(id);
        window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
            'facebook-share-dialog',
            'width=626,height=436');
        return false;
    });

    $(".pinterest-share").click(function () {
        let id = $(this).attr("oid");
        let image_url = $(this).attr("image");
        let url = _share_url(id);
        window.open(
            'http://pinterest.com/pin/create/button/?url=' + encodeURIComponent(url) + '&media=' + encodeURIComponent(image_url),
            'pinterest-share-dialog',
            'width=626,height=436');

        return false;
    });

    $(".twitter-share").click(function () {
        let id = $(this).attr("oid");
        let url = _share_url(id);
        window.open(
            'https://twitter.com/share?url=' + encodeURIComponent(url),
            'twitter-share-dialog',
            'width=626,height=436');
        return false;
    });
});


// Lazy loading images
function ReLoadImages() {
    $('img[data-lazysrc]').each(function () {
            //* set the img src from data-src
            $(this).attr('src', $(this).attr('data-lazysrc'));
        }
    );
}

document.addEventListener('readystatechange', event => {
    if (event.target.readyState === "interactive") {  //or at "complete" if you want it to execute in the most last state of window.

    }
});

$(document).on("scroll", function () {
    var pageTop = $(document).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var tags = $(".page-content .page-content-inner, .animate-opacity");

    for (var i = 0; i < tags.length; i++) {
        var tag = tags[i];
        if (pageTop == 0 && $(tag).hasClass('page-content-inner')) {
            $(tag).removeClass("visible");
        } else if ($(tag).position().top < pageBottom) {
            $(tag).addClass("visible");
        } else {
            $(tag).removeClass("visible");
        }
    }
});

$(document).on("DOMContentLoaded", function () {

    var pageTop = $(document).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var tags = $(".animate-opacity");

    setTimeout(function () {
        for (var i = 0; i < tags.length; i++) {
            var tag = tags[i];
            if ($(tag).position().top < pageBottom) {
                $(tag).addClass("visible");
            }
        }
    }, 500)


});
